import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from './resource';
import imgSrc from 'images/thumbnail-pat-broch.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Patient Brochure"
      description="Download the AVEED<sup>&reg;</sup> Patient Brochure to learn more about hypogonadism, treating hypogonadism with AVEED<sup>&reg;</sup>, and how to save on your AVEED<sup>&reg;</sup> therapy."
      thumbnail={imgSrc}
      altText="AVEED® Patient Brochure"
    >
      <a href={withPrefix('/pdfs/aveed-patient-brochure.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Brochure</a>
    </Resource>
  )
}
