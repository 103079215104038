import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from './resource';
import imgSrc from 'images/thumbnail-patient-counseling-tool.png'

export default function index() {
  return (
    <Resource
      title="Patient Counseling Tool"
      description='You can download and print this helpful guide for your patients to provide them with information about AVEED<sup>&reg;</sup>, and what they can expect during and after their treatment with AVEED<sup>&reg;</sup>.'
      thumbnail={imgSrc}
      altText="Patient Counseling Tool"
    >
      <a href={withPrefix('/pdfs/AVEED_REMS_What_To_Expect_Patient_Guide_June-2021.pdf')}
        target="_blank"
        className="aveed-button green resource-button"
        rel="noopener noreferrer">Review Patient Guide</a>
    </Resource>
  )
}
