import React from "react";
import Resource from "./resource";
import { withPrefix } from "gatsby";
import imgSrc from "images/thumbnail-doctor-discussion.png";

export default function index() {
  return (
    <Resource
      title="Doctor Discussion Guide"
      description="Download this tool to help aid in your conversation with your physician."
      thumbnail={imgSrc}
      altText="Doctor Discussion Guide"
    >
      <a
        href={withPrefix("/pdfs/doctor-discussion-guide.pdf")}
        target="_blank"
        className="aveed-button green resource-button"
        rel="noopener noreferrer"
      >
        Download Discussion Guide
      </a>
    </Resource>
  );
}
