import React from 'react';
import Resource from './resource';
import imgSrc from 'images/thumbnail-aveed-medication-guide.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Medication Guide"
      description='Download a complete overview of AVEED<sup>&reg;</sup>, including important information, possible side effects, and more.'
      thumbnail={imgSrc}
      altText="AVEED® Medication Guide"
    >
      <a href="https://endodocuments.com/AVEED/MG"
        target="_blank"
        className="aveed-button green resource-button" rel="noopener noreferrer">View Medication Guide</a>
    </Resource>
  )
}
