import React from 'react';
import Resource from './resource';
import imgSrc from 'images/thumbnail-aveed-rebate-form-new.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Rebate Form"
      description='If you already know you are eligible for a rebate, you can download and print a Rebate Form, and submit your completed form <a href="http://www.msm-services.com/aveed/submitForm.php" target="_blank">here</a>.'
      thumbnail={imgSrc}
      altText="AVEED® Rebate Form"
    >
      <a href="http://www.msm-services.com/aveed/download_info.php" target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Form</a>
    </Resource>
  )
}
