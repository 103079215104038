import React from 'react';
import Resource from './resource';
import imgSrc from 'images/thumbnail-copay-assistance.png'
import { withPrefix } from 'gatsby';

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Copay Assistance Program"
      description="Find out how your eligible patients can save on their AVEED<sup>&reg;</sup> therapy."
      thumbnail={imgSrc}
      altText="AVEED® Copay Assistance Program"
    >
      <div className="buttons-block">
        <a href='/hcp/paying-for-aveed/' className="aveed-button green resource-button">Learn More About the Program</a>
        <a href={withPrefix('/pdfs/AV-05294_AVEED_Copay_Assistance_Program_Form_Final.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download AVEED<sup>&reg;</sup> Rebate Form</a>
      </div>
    </Resource>
  )
}
