import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from '../resource';
import imgSrc from 'images/thumbnail-aveed-patient-brochure.png'

export default function index() {
  return (
    <Resource
      title="AVEED<sup>&reg;</sup> Patient Brochure"
      description="You can download and print this brochure for your patients to provide them with more information about hypogonadism, treating hypogonadism with AVEED<sup>&reg;</sup>, and how to save on their AVEED<sup>&reg;</sup> injections."
      thumbnail={imgSrc}
      altText="AVEED® Patient Brochure"
    >
      <a href={withPrefix('/pdfs/AV-05268_Aveed_Patient_Brochure_DIGITAL_clean__1_.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Brochure</a>
    </Resource>
  )
}
