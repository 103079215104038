import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { PatientPage, SEO } from "components";
import {
  AveedPatientBrochure,
  AveedRebateForm,
  AveedMedicationGuide,
  DiscussionGuide
} from "components/shared/resources";

import "./resources.scss";

const pageDescription = "Find patient resources for AVEED® (testosterone undecanoate) injection CIII, including patient brochure, rebate form, patient authorization, and Med Guide.";

const ResourcesPage = () => {
  return (
    <PatientPage pageClassName="patient-resources">
      <SEO pageTitle="Patient Resources" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1>Resources</h1>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <AveedPatientBrochure />
          </Col>
          <Col sm={12} md={6} className="resource-col">
            <AveedRebateForm />
          </Col>
        </Row>
        <Row className="resource-row">
          <Col sm={12} md={6} className="resource-col">
            <AveedMedicationGuide />
          </Col>
          <Col sm={12} md={6}>
            <DiscussionGuide />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="h1">Questions About Coverage for Your AVEED<sup>&reg;</sup> Therapy?</p>
            <h2 style={{ paddingTop: 0 }}>We're here to help</h2>
            <p>Get answers about your coverage, assistance with benefit verification, information about your copay, and more.</p>
            <p>Call <strong>1-855-myAVEED (692-8333)</strong> to get started.</p>
          </Col>
        </Row>
      </div>
    </PatientPage>
  );
};

export default ResourcesPage;
