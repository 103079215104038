import React from "react";

export default function Resource({
  children,
  className,
  title,
  description,
  thumbnail,
  altText
}) {
  return (
    <div className={`resource-box ${className}`}>
      <div className="top-row">
        <div className="thumbnail-container">
          <img src={thumbnail} alt={altText} />
        </div>
        <div className="description-container">
          <p className="resource-title" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="resource-description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
      <div className="bottom-row resource-link">
        {children}
      </div>
    </div>
  );
}
