import React from 'react';
import { withPrefix } from 'gatsby'
import Resource from './resource';
import imgSrc from 'images/thumbnail-benefits-investigation-form.png'

export default function index() {
  return (
    <Resource
      title="Benefits Investigation Form"
      description="Download the benefits investigation form."
      thumbnail={imgSrc}
      altText="Benefits Investigation Form"
    >
      <div className="buttons-block">
        <a href={withPrefix('/pdfs/benefits-investigation-enrollment-form.pdf')} target="_blank" className="aveed-button green resource-button" rel="noopener noreferrer">Download Form</a>
      </div>
    </Resource>
  )
}
